import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';


class MarketoSubscription extends AEM.Component {
    init() {
        this.preferredLanguage = 'en_US';
        this.selectedTopics = [];

        this.marketoCheckBoxFields = new Set([ ...this.element.querySelectorAll('[type="checkbox"]') ].map(e => e.name));
        this.marketoRadioFields = new Set([ ...this.element.querySelectorAll('[type="radio"]') ].map(e => e.name));

        [ ...this.element.querySelectorAll('.dynamic-options template') ].map(template => {
            [ ...template.content.querySelectorAll('[type="radio"]') ].map(element => {
                this.marketoRadioFields.add(element.name);
            });
            [ ...template.content.querySelectorAll('[type="checkbox"]') ].map(element => {
                this.marketoCheckBoxFields.add(element.name);
            });
        });

        this.allFields = [ ...this.marketoRadioFields, ...this.marketoCheckBoxFields ];
        this.element.querySelector('input[type=hidden][name="allmarketoCheckBoxfields"]').value = [ ...this.marketoCheckBoxFields ];
        this.element.querySelector('input[type=hidden][name="allmarketoRadiofields"]').value = [ ...this.marketoRadioFields ];

        this.getData();
    }


    getData() {
        fetch(`/bin/protected/account/preferencecenter?fields=${this.allFields}`)
            .then(response => response.json())
            .then(response => {
                this.preferredLanguage = response.marketoPreferenceCenter.preferredLanguage;
                this.selectedTopics = response.marketoPreferenceCenter.topics;
                this.prefillSubscriptionForm();
            })
            .catch(error => {
                console.log(error);
                console.log('Failed to get the user data and prefill');
            });
    }

    prefillSubscriptionForm() {
        const dynamicTemplates = [ ...this.element.querySelectorAll('.dynamic-options template') ];

        // First set the Dynamic options inside the template
        this.selectedTopics.map(topicData => {
            const [ topicId, value ] = Object.entries(topicData)[0];

            dynamicTemplates.map(template => {
                if (value === 'true' || value === 'false') {
                    const checkBox = template.content.querySelector(`input[type="checkbox"][name="${topicId}"][value="${value}"`);
                    if (checkBox && value === 'true'){
                        checkBox.checked = true;
                    }
                } else {
                    const radio = template.content.querySelector(`input[type="radio"][name="${topicId}"][value="${value}"]`);
                    if (radio && value){
                        radio.checked = true;
                    }
                }
            });
        });

        this.selectedTopics.map(topicData => {
            const [ topicId, value ] = Object.entries(topicData)[0];
            if (value === 'true' || value === 'false') {
                // Prefill the topic Id checkboxes
                const checkboxElement = this.element.querySelector(`input[type="checkbox"][name="${topicId}"]`);
                if (checkboxElement && value === 'true') {
	                checkboxElement.checked = true;
	                checkboxElement.dispatchEvent(new Event('change'));
	            }
            } else {
                // Prefill the topic Id radios
                const radioElement = this.element.querySelector(`input[type="radio"][name="${topicId}"][value="${value}"]`);
                if (radioElement && value) {
	                radioElement.checked = true;
	                radioElement.dispatchEvent(new Event('change'));
	            }
            }
        });


        // Prefill the language
        this.element.querySelector('select[name="Locale"]').value = this.preferredLanguage;
    }
}

export { MarketoSubscription };
