function profileFetch(url = '', args = {}) {
    let language = document.querySelector('html').lang;
    let languageUrl = url;
    if (language){
        languageUrl = `${url }?language=${language}`;
    }

    const response = fetch(languageUrl, args);
    return response;
}

export default {
    profileFetch: profileFetch
};
