import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import lounges from './lounges.templates.handlebars';
import UserAccountFetch from 'base/js/templates/general/userAccountFetch';

class Lounges extends AEM.Component {
    async init() {
        const { getOptions, DataTable } = await import(/* webpackChunkName: "datatables" */
            /* webpackMode: "lazy" */
            'common-ui-frontend/src/main/webpack/base/js/templates/general/datatables-loader.js');
        this.DataTable = DataTable;
        this.getOptions = getOptions;
        this.getData();
    }

    getData() {
        UserAccountFetch.profileFetch('/bin/protected/account/lounges', {
            cache: 'no-store'
        })
            .then(res => res.json())
            .then(response => {
                this.populateData(response.lounges);
            })
            .catch(err => {
                console.log(err);
                console.log('AJAX Error');
            });
    }

    populateData(results) {
        this.template = lounges;

        this.element.innerHTML = this.template({
            secureSiteLabel: this.props.secureSiteLabel,
            expirationLabel: this.props.expirationLabel,
            lounges: results
        });

        const table = this.element.querySelector('table');
        if (table) {
            const options = this.getOptions(this);
            const dataTable = new this.DataTable(table, options);
        }
    }
}

export { Lounges };
