import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import { createApp } from 'vue';
import omsOrderHistoryDetailsTemplate from './omsOrderHistoryDetails.vue';

class OmsOrderHistoryDetails extends AEM.Component {
    init() {
        this.showDataTable();
    }

    showDataTable() {
        const omsOrderHistoryDetailsComponent = createApp(omsOrderHistoryDetailsTemplate);
        const omsOrderHistoryDetailsPlaceholder = this.element.querySelector('.cmp-omsorderhistorydetails-placeholder');

        omsOrderHistoryDetailsComponent.provide('orderNumberLabel', this.props.ordernumberLabel);
        omsOrderHistoryDetailsComponent.provide('orderDateLabel', this.props.orderdateLabel);
        omsOrderHistoryDetailsComponent.provide('purchaseDateLabel', this.props.purchasedateLabel);
        omsOrderHistoryDetailsComponent.provide('statusLabel', this.props.statusLabel);
        omsOrderHistoryDetailsComponent.provide('paymentStatusLabel', this.props.paymentstatusLabel);
        omsOrderHistoryDetailsComponent.provide('qtyLabel', this.props.qtyLabel);
        omsOrderHistoryDetailsComponent.provide('trackingLabel', this.props.trackingLabel);
        omsOrderHistoryDetailsComponent.provide('unitPriceLabel', this.props.unitpriceLabel);
        omsOrderHistoryDetailsComponent.provide('subtotalLabel', this.props.subtotalLabel);
        omsOrderHistoryDetailsComponent.provide('shippingLabel', this.props.shippingLabel);
        omsOrderHistoryDetailsComponent.provide('taxLabel', this.props.taxLabel);
        omsOrderHistoryDetailsComponent.provide('totalLabel', this.props.totalLabel);
        omsOrderHistoryDetailsComponent.provide('shippingAddressLabel', this.props.shippingaddressLabel);
        omsOrderHistoryDetailsComponent.provide('billingAddressLabel', this.props.billingaddressLabel);
        omsOrderHistoryDetailsComponent.provide('notfoundLabel', this.props.notfoundLabel);
        omsOrderHistoryDetailsComponent.provide('redeemLicenseLabel', this.props.redeemlicenseLabel);
        omsOrderHistoryDetailsComponent.provide('trackingPendingLabel', this.props.trackingpendingLabel);
        omsOrderHistoryDetailsComponent.provide('voucherLabel', this.props.voucherLabel);
        omsOrderHistoryDetailsComponent.provide('shippedDateLabel', this.props.shippeddateLabel);
        omsOrderHistoryDetailsComponent.provide('scheduledShipDateLabel', this.props.scheduledshipdateLabel);

        omsOrderHistoryDetailsComponent.mount(omsOrderHistoryDetailsPlaceholder);
    }
}


export { OmsOrderHistoryDetails };
