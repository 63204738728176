<template>
    <DataTable class="display" :data="orders" :options="dataTableOptions">
        <thead>
            <tr>
                <th>{{ordernumberLabel}}</th>
                <th>{{orderdateLabel}}</th>
                <th>{{purchasedateLabel}}</th>
                <th>{{statusLabel}}</th>
                <th>{{totalLabel}}</th>
            </tr>
        </thead>
    </DataTable>
</template>

<script setup>
    import DataTable from 'datatables.net-vue3/dist/datatables.net-vue3.mjs';
    import DataTablesLib from "datatables.net";
    import DataTablesCore from 'datatables.net/js/jquery.dataTables.mjs';
    import 'datatables.net-responsive-bs5/js/responsive.bootstrap5.mjs';
    import FixedColumns from 'datatables.net-fixedcolumns/js/dataTables.fixedColumns.mjs';

    DataTable.use(DataTablesLib);
    DataTable.use(DataTablesCore);
    DataTable.use(FixedColumns);


    import { ref, inject, onMounted} from 'vue';
    const orders = ref([]);
    const dataTableOptions = {
        order: [[2, 'desc']],
        columnDefs: [
            { type: 'date', targets: [1, 2] }
        ],
        language: {
            dateFormat: 'MMM dd, yyyy' 
        }
    };

    const ordernumberLabel = inject('ordernumberLabel');
    const purchasedateLabel = inject('purchasedateLabel');
    const statusLabel = inject('statusLabel');
    const totalLabel = inject('totalLabel');
    const orderdateLabel = inject('orderdateLabel');
    const currentLang = inject('currentLang');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        };
        return date.toLocaleDateString('en-US', options);
    }

    function getOmsOrderHistory() {
        fetch('/bin/protected/oms/orderhistory', {
                cache: 'no-store'
            }).then(res => res.json())
                .then(response => {
                    if(response.success){
                        response.orders.forEach((item) => {
                            orders.value.push([
                                `<a href="/${currentLang}/profile/order-history/order-details.html?orderId=${item.orderId}">${item.orderNumber}</a>`,
                                formatDate(item.orderDate),
                                formatDate(item.purchaseDate),
                                item.total,
                                item.orderStatus
                            ]);
                        });
                    }else{
                        throw new Error("Failed to fetch the OMS Order history.");
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        };

    onMounted(() => {
        getOmsOrderHistory();   
    });
    
</script>
