import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import UserAccountFetch from 'base/js/templates/general/userAccountFetch';

class UserProfile extends AEM.Component {
    init() {
        this.getProfile();
    }

    getProfile() {
        UserAccountFetch.profileFetch('/bin/protected/account/profile', {
            cache: 'no-cache'
        })
            .then(res => res.json())
            .then(response => {
                this.populateData(response);
            })
            .catch(err => {
                console.log(err);
                console.log('AJAX Error');
            });
    }

    populateData(res) {
        if (typeof res.email !== 'undefined') {
            this.element.querySelector('input[name="Email"]').value = res.email;
            if (res.email.endsWith('@xilinx.com') || res.email.endsWith('@amd.com')) {
                this.element.querySelector('input[name="First_Name"]').readOnly = true;
                this.element.querySelector('input[name="Last_Name"]').readOnly = true;
                this.element.querySelector('input[name="Company"]').readOnly = true;
            }
        }

        this.element.querySelector('input[name="First_Name_Lang"]').value = res.firstNameLang || '';
        this.element.querySelector('input[name="Last_Name_Lang"]').value = res.lastNameLang || '';
        this.element.querySelector('input[name="First_Name"]').value = res.firstName || '';
        this.element.querySelector('input[name="Last_Name"]').value = res.lastName || '';
        this.element.querySelector('input[name="Company_Lang"]').value = res.organizationLang || '';
        this.element.querySelector('input[name="Company"]').value = res.organization || '';
        this.element.querySelector('input[name="Address_1"]').value = res.streetAddress || '';
        this.element.querySelector('input[name="Address_2"]').value = res.streetAddress2 || '';
        this.element.querySelector('input[name="City"]').value = res.city || '';
        this.element.querySelector('input[name="Zip_Code"]').value = res.zipCode || '';
        this.element.querySelector('input[name="Phone"]').value = res.primaryPhone || '';

        let jb = this.element.querySelector('select[name="Job_Function"]');
        if (typeof jb !== 'undefined' && jb !== null) {
            jb.value = res.jobFunction || '';
        }

        if (typeof res.countryCode === 'undefined') {
            res.countryCode = '';
	    }      
        if (typeof res.countryCode !== 'undefined') {
            let val = res.countryCode;
            this.element.querySelector('select[name="Country"], input[type="hidden"][name="Country"]').value = val;


            if (this.hasStates(val)) {
                let stateId = `State_${val}`;
                this.element.querySelector(`select[id="${stateId}"][name="State"]`).value = res.state || '';
                this.enableStateField(val);
            } else {
                this.element.querySelector('input[name="State"]').value = res.state || '';
                this.enableStateField();
            }


            this.element.querySelector('select[name="Country"]').addEventListener('change', event => {
                let countryValue = event.target.value;
                this.hasStates(countryValue) ? this.enableStateField(countryValue) : this.enableStateField();
            });
        }
    }

    enableStateField(selectedCountry) {
        if (selectedCountry === 'US' || selectedCountry === 'CA' || selectedCountry === 'MX') {
            this.element.querySelector('label[for="Zip_Code"] span').classList.add('required');
        } else {
            this.element.querySelector('label[for="Zip_Code"] span').classList.remove('required');
        }

        if (this.hasStates(selectedCountry)) {
            this.element.querySelector('label[for="State"] span').classList.add('required');
        } else {
            this.element.querySelector('label[for="State"] span').classList.remove('required');
        }

        this.element.querySelectorAll('[name="State"]').forEach(currState => {
            let currentCountry = selectedCountry || 'REST';
            let stateId = `State_${currentCountry}`;

            if (stateId !== currState.id) {
                currState.style.display = 'none';
                currState.disabled = true;
            } else {
                currState.style.display = 'block';
                currState.disabled = false;
            }
        });
    }

    hasStates(country) {
        switch (country) {
        case 'US':
        case 'CA':
        case 'AU':
        case 'BR':
        case 'IE':
        case 'IN':
        case 'IT':
        case 'MX':
            return true;
        default:
            return false;
        }
    }
}

export { UserProfile };
