import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';

const MAXLENGTH = 72;
const MINLENGTH = 10;
class PasswordVerification extends AEM.Component {
    async init() {
        const zxcvbn = await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn');

        this.password = this.element.querySelector('[data-type="password"]');
        this.passwordVerification = this.element.querySelector('[data-type="verification"]');
        this.passwordText = this.element.querySelector('[data-function="password-text"]');
        this.passwordVerificationText = this.element.querySelector('[data-function="verification-text"]');
        this.meter = this.element.querySelector('[data-function="meter"]');
        this.score = 0;


        this.password.addEventListener('input', event => {
            let statusText = '';
            let val = event.target.value;
            let length = val.length;
            if (length <= MAXLENGTH && length >= MINLENGTH) {
                let results = zxcvbn.default(val);

                this.score = results.score;

                let regexp = '^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$';

                // Return true if difficult.
                let difficult = !val.match(regexp);

                if (difficult) {
                    if (this.score >= 3) {
                        this.score = this.score + 2;
                    }
                }

                switch (this.score) {
                case 0:
                    statusText = this.props.passwordWeakLabel;
                    break;
                case 1:
                    statusText = this.props.passwordWeakLabel;
                    break;
                case 2:
                    statusText = this.props.passwordWeakLabel;
                    break;
                case 3:
                    statusText = this.props.passwordMediumLabel;
                    break;
                case 4:
                    statusText = this.props.passwordMediumLabel;
                    break;
                case 5:
                    statusText = this.props.passwordGoodLabel;
                    break;
                case 6:
                    statusText = this.props.passwordStrongLabel;
                    break;
                default:
                    break;
                }
            } else if (length < MINLENGTH) {
                this.score = 0;
                statusText = this.props.passwordMinimumLabel;
            } else if (length > MAXLENGTH) {
                this.score = 1;
                statusText = this.props.passwordMaximumLabel;
            }

            this.meter.value = this.score;
            this.passwordText.innerHTML = `${this.props.passwordStrengthLabel }: ${statusText}`;
            this.checkPasswordMatch();
        });

        this.passwordVerification.addEventListener('input', () => {
            this.checkPasswordMatch();
        });
    }

    checkPasswordMatch() {
        let val = this.passwordVerification.value;
        let passwordVal = this.password.value;

        if (val !== passwordVal) {
            this.passwordVerificationText.innerHTML = `${this.props.passwordMatchLabel }: 
                <span class="noMatch">${this.props.passwordMatchNo}</span>`;
        } else {
            this.passwordVerificationText.innerHTML = `${this.props.passwordMatchLabel }: 
                <span class="match">${this.props.passwordMatchYes}<span>`;
        }
    }
}

export { PasswordVerification };
