<template>
    <section>
      <div v-if="order && !notFound">
        <div class="order-summary-column">

            <div class="row">
                <div class="col-12 col-md-3">
                    <p><strong>{{ orderNumberLabel }}</strong><br>{{ order.orderNumber }}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p><strong>{{ orderDateLabel }}</strong><br>{{ formatDate(order.orderDate) }}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p><strong>{{ purchaseDateLabel }}</strong><br>{{ formatDate(order.purchaseDate) }}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p><strong>{{ statusLabel }}</strong><br>{{ order.orderStatus }}</p>
                </div>
            </div>

        </div>
        <div class="product-list-item">
            <div class="text">
                <div class="cmp-text">
                    <h2> Product Line Items </h2>
                </div>
            </div>
          <div v-for="product in productListItems" :key="product.description" class="row">
              <div class="col-12 col-md-4">
                <img :src="product.imageUrl" alt="Product Image" class="img-fluid" role="img" aria-label="Product Image">
                <h3>{{ product.description }}</h3>
                <p v-if="product.alternatePartNumberUrl">
                    <a :href="product.alternatePartNumberUrl">{{ product.alternatePartNumber }}</a>
                </p>
                <p v-else>{{ product.alternatePartNumber }}</p>
                <p v-if="product.licenseIdLabel">
                    <a href="https://www.xilinx.com/getlicense">{{ product.licenseIdLabel }}</a>
                </p>
              </div>
              <div class="col-12 col-md-8">
                  <div class="row">
                    <div class="col-12 col-md-3">
                      <p><strong>{{ product.trackingLabel }}</strong><br>{{ product.shippingTrackingNumber ? product.shippingTrackingNumber : product.trackingPendingLabel }}</p>
                    </div>
                    <div class="col-12 col-md-3">
                      <p><strong>{{ product.shippedDate ? shippedDateLabel : scheduledShipDateLabel }}</strong><br>{{ product.shippedDate ? formatDate(product.shippedDate) : (product.scheduledShipDate ? formatDate(product.scheduledShipDate) : '') }}</p>
                    </div>
                    <div class="col-12 col-md-3">
                      <p><strong>{{ paymentStatusLabel }}</strong><br>{{ product.paymentStatus }}</p>
                    </div>
                    <div class="col-12 col-md-1">
                      <p><strong>{{ qtyLabel }}</strong><br>{{ product.qty }}</p>
                    </div>
                    <div class="col-12 col-md-2">
                      <p class="text-end"><strong>{{ unitPriceLabel }}</strong><br>{{ product.unitPrice }}</p>
                    </div>
                  </div>
              </div>
            <hr>
          </div>
        </div>
        <div class="order-other-details">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">

                  <div class="row">
                    <div class="col-6">
                      <p><strong>{{ subtotalLabel }}</strong><br></p>
                    </div>
                    <div class="col-6">
                      <p class="text-end">{{ order.subTotal }}<br></p>
                    </div>
                  </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">

                  <div class="row">
                    <div class="col-6">
                      <p><strong>{{ shippingLabel }}</strong><br></p>
                    </div>
                    <div class="col-6">
                      <p class="text-end">{{ order.shipping }}<br></p>
                    </div>
                  </div>
  
              </div>
            </div>
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">

                  <div class="row">
                    <div class="col-6">
                      <p><strong>{{ taxLabel }}</strong><br></p>
                    </div>
                    <div class="col-6">
                      <p class="text-end">{{ order.tax }}<br></p>
                    </div>
                  </div>

              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">

                  <div class="row">
                    <div class="col-6">
                      <h2><strong>{{ totalLabel }}</strong></h2>
                    </div>
                    <div class="col-6">
                      <h2 class="text-end">{{ order.total }}</h2>
                    </div>
                  </div>

              </div>
            </div>

          <!-- Address section -->
          <div class="text">
              <div class="row">
                <div class="col-12">
                  <p><strong>{{ billingAddressLabel }}</strong><br>{{ formatAddress(order.billingAddress) }}</p>
                </div>
              </div>

          </div>
          <!-- Shipping address section -->
          <div class="text">
              <div class="row">
                <div class="col-12">
                  <p><strong>{{ shippingAddressLabel }}</strong><br>{{ formatAddress(order.shippingingAddress) }}</p>
                </div>
              </div>
          </div>
          <!-- Divider -->
          <hr>
        </div>
      </div>
      <div v-else-if="notFound">
          <div class="row">
            <div class="col-12">{{ notfoundLabel }}</div>
          </div>
      </div>
    </section>
  </template>
  
  
  
  <script setup>
  import { ref, inject, onMounted } from 'vue';
  import UrlUtil from 'common-ui-frontend/src/main/webpack/base/js/templates/general/urlUtil';

  const orderNumberLabel = inject('orderNumberLabel');
  const orderDateLabel = inject('orderDateLabel');
  const purchaseDateLabel = inject('purchaseDateLabel');
  const statusLabel = inject('statusLabel');
  const paymentStatusLabel = inject('paymentStatusLabel');
  const qtyLabel = inject('qtyLabel');
  const trackingLabel = inject('trackingLabel');
  const unitPriceLabel = inject('unitPriceLabel');
  const subtotalLabel = inject('subtotalLabel');
  const shippingLabel = inject('shippingLabel');
  const taxLabel = inject('taxLabel');
  const totalLabel = inject('totalLabel');
  const shippingAddressLabel = inject('shippingAddressLabel');
  const billingAddressLabel = inject('billingAddressLabel');
  const notfoundLabel = inject('notfoundLabel');
  const redeemLicenseLabel = inject('redeemLicenseLabel');
  const trackingPendingLabel = inject('trackingPendingLabel');
  const voucherLabel = inject('voucherLabel');
  const shippedDateLabel = inject('shippedDateLabel');
  const scheduledShipDateLabel = inject('scheduledShipDateLabel');
  
  const orders = ref([]);
  const order = ref(null);
  const notFound = ref(false);
  const productListItems = ref([]);
  
  const getOrderHistoryItems = async () => {
    try {
      const res = await fetch('/bin/protected/oms/orderhistory');
      const data = await res.json();
      orders.value = data.orders;
      getProductListItems();
    } catch (err) {
      console.error('AJAX Error:', err);
    }
  };
  
  onMounted(() => {
    getOrderHistoryItems();
  });


  const getProductListItems = () => {
    const orderId = UrlUtil.getURLParameter('orderId');
    if(orderId && Number(orderId)){
        const filteredOrder = orders.value.filter(item => item.orderId == Number(orderId));
        if(filteredOrder && filteredOrder.length > 0){
            order.value = filteredOrder[0];
        
            productListItems.value =  order.value.lineItems.map(lineItem => ({
                description: lineItem.description,
                alternatePartNumber: order.value.orderNumber.startsWith('EVOU-') && lineItem.alternatePartNumber.startsWith('EF-')
                ? `<b>${voucherLabel}</b> ${lineItem.alternatePartNumber}`
                : lineItem.alternatePartNumber,
                paymentStatus: lineItem.paymentStatus,
                qty: lineItem.quantity,
                unitPrice: lineItem.unitPrice,
                imageUrl: lineItem.imageUrl ? lineItem.imageUrl : '/content/dam/code/images/cart/no-image-available.jpg',
                pageUrl: lineItem.pageUrl,
                alternatePartNumberUrl: order.value.orderNumber.startsWith('EVOU-') && lineItem.alternatePartNumber.startsWith('EF-')
                ? ''
                : lineItem.pageUrl,
                licenseIdLabel: order.value.orderNumber.startsWith('EVOU-') && lineItem.alternatePartNumber.startsWith('EF-')
                ? redeemLicenseLabel
                : '',
                shippingTrackingNumber: lineItem.shippingTrackingNumber ? lineItem.shippingTrackingNumber : '',
                trackingLabel: lineItem.shippingCarrier
                ? `${lineItem.shippingCarrier} ${trackingLabel}`
                : trackingLabel,
                trackingPendingLabel: trackingPendingLabel,
                shippedDate: lineItem.shippingDate ? lineItem.shippingDate : '',
                scheduledShipDate: lineItem.scheduledShipDate ? lineItem.scheduledShipDate : ''
            }));
        }else{
            notFound.value = true;
        }
    }
    
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    };
    return date.toLocaleDateString('en-US', options);
}

const formatAddress = (addressObj) => {
		if (addressObj) {
			
			var fullAddr = addressObj.firstName+' '+addressObj.lastName;
			if(addressObj.company){
				fullAddr+=', '+addressObj.company;
			}
			if(addressObj.address1){
				fullAddr+=', '+addressObj.address1;
			}
			if(addressObj.address2){
				fullAddr+=', '+addressObj.address2;
			}
			if(addressObj.city){
				fullAddr+=', '+addressObj.city;
			}
			if(addressObj.state){
				fullAddr+=', '+addressObj.state;
			}
			if(addressObj.postalCode){
				fullAddr+=', '+addressObj.postalCode;
			}
			if(addressObj.country){
				fullAddr+=', '+addressObj.country;
			}
			if(addressObj.phone){
				fullAddr+=', '+addressObj.phone;
			}
			return fullAddr;
		}
		return '';
	}
  
  
  </script>