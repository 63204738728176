import AEM from 'common-ui-frontend/src/main/webpack/base/js/aem';
import { createApp } from 'vue';
import omsOrderHistoryTemplate from './omsOrderHistory.vue';

class OmsOrderHistory extends AEM.Component {
    init() {
        this.showDataTable();
    }

    showDataTable() {
        const omsOrderHistoryComponent = createApp(omsOrderHistoryTemplate);
        const omsOrderHistoryPlaceholder = this.element.querySelector('.cmp-omsorderhistory-placeholder');

        let currentLang = 'en';

        if (this.props.currentpage && this.props.currentpage.split('/').length > 4){
            currentLang = this.props.currentpage.split('/')[4];
        }

        omsOrderHistoryComponent.provide('ordernumberLabel', this.props.ordernumberLabel);
        omsOrderHistoryComponent.provide('purchasedateLabel', this.props.purchasedateLabel);
        omsOrderHistoryComponent.provide('statusLabel', this.props.statusLabel);
        omsOrderHistoryComponent.provide('totalLabel', this.props.totalLabel);
        omsOrderHistoryComponent.provide('orderdateLabel', this.props.orderdateLabel);
        omsOrderHistoryComponent.provide('currentLang', currentLang);


        omsOrderHistoryComponent.mount(omsOrderHistoryPlaceholder);
    }
}


export { OmsOrderHistory };
